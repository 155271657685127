/* eslint-disable import/prefer-default-export */

/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const offerLPHero = {
  title: "Save up to $500 on the top-rated POS for restaurants",
  subtext:
    "We get it. Switching your POS can be a pain. We’re making it easy with $500 off on POS tech that works the way you work—backed by real humans who make sure it always does.",
  heroImg: "auto-lp2.jpg",
  imageClassName: "smaller-hero",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/offer/learn-more",
  },
  fatTitle: "",
};

// export const offerLPWhiteGlove = {
//   mainTitle: "Technology that performs, from a partner who listens",
//   title: "Five star service & support",
//   content:
//     "Our team will work closely with you to configure your point-of-sale or payment device in as little as 48 hours and then train you and your staff to get the most from it. Once you’re up and running, we’ll continue to be there for you with local service and around-the-clock technical support, included at no extra cost.",
//   ctaInfoPrimary: {
//     ctaTitle: "Learn more",
//     ctaTarget: "/lp/offer/learn-more",
//   },
// };

export const offerLPLargeFeatures = {
  sectionTitle: "The tech you need to run & grow your business",
  featureBlocks: [
    {
      blockTitle: "Serve more guests & streamline operations",
      blockImg: "vets-a.png",
      blockSubTitle: "",
      blockList: [
        "Turn more tables with lightning-fast POS, handhelds, and QR ordering",
        "Increase sales with commission-free online & mobile ordering",
        "Improve efficiency with integrated front-of-house and back-of-house tools",
        "Get smarter reporting and customer insights—available online anytime, anywhere",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/offer/learn-more",
      },
    },
    {
      blockTitle: "Save time and money on labor management",
      blockImg: "vets-b.png",
      blockList: [
        "Create data-driven schedules that boost profits—in a fraction of the time",
        "Let staff view and manage their schedules and stay connected with a mobile app",
        "Run payroll in minutes with software that syncs and verifies POS data with your payroll provider",
        "Automate tip distributions for faster payouts and get staff off the clock faster",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/offer/learn-more",
      },
    },
  ],
};

export const offerLPTestimonials = {
  title: "",
  name: "Ardith P.",
  imgs: "",
  subtext:
    "“The system is great and easy to use and learn. The huge benefit for us is our local sales representative. She has held my hand and walked me through so many learning curves and is always friendly and available to help.”",
};

export const offerLPCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/offer/learn-more",
  },
};

export const customOffer = {
  title: "Driven by Heart & Hustle",
  subtext:
    "You built your hospitality business on hard work, integrity, and treating guests with respect. You deserve a partner who shares those values. We built SpotOn to empower restaurant managers and operators—with technology that works the way you work.",
  imgs: ["vets-1.png", "vets-2.png", "vets-3.png"],
};
