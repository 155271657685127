import React from "react";
import loadable from "@loadable/component";
import LpLayout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  offerLPHero,
  // offerLPWhiteGlove,
  offerLPLargeFeatures,
  offerLPCTA,
  // offerLPTestimonials,
  customOffer,
} from "../../../data/landing/offer-data";
import Hero from "../../../components/Hero/hero";
import ogImage from "../../../images/global_assets/og-image.png";
import Section from "../../../components-v2/Base/Section";
import Image from "../../../components/ImageQuerys/RandomImages";

// import InlineQuote from "../../../components/InlineQuote";

// const WhiteGlove = loadable(() =>
//   import("../../../components/WhiteGlove/WhiteGlove")
// );

const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);

const VetsSpecial = loadable(() => import("../../../components/VetsSpecial"));

const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));

// const whiteGloveImport = "vets-white-glove.png";

const OrderingLP = () => {
  return (
    <LpLayout isLanding phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="Limited Time Offer | Restaurant POS | SpotOn"
        description="Save up to $500 on a new restaurant point-of-sale, with built-in tools to run and grow your business and the personalized service and support you deserve."
        image={`https://spoton.com/${ogImage}`}
        robotsData="noindex, nofollow"
      />
      <Hero
        sectionData={offerLPHero}
        titleClassName="xl:max-w-[448px]"
        paragraphClassName="xl:max-w-[448px]"
        // circleBg={false}
        // style={{ paddingTop: "0px !important" }}
        hideBanner
      />

      <Section className="mt-16 lg:mt-24 mb-20">
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 max-w-[1120px] 2xl:min-w-[1120px] mx-auto">
          <div className="">
            <Image imageName="offer-lp-image.png" />
          </div>

          <div className="bg-primary-30 rounded-[16px] p-8 md:p-12 flex flex-col items-center justify-center">
            <p className="text-black text-p md:text-[24px]">
              “SpotOn is not just a POS. It's a communications device. It's a
              reporting device. It's a labor management tool. If you’re looking
              for a POS system that going to help you run your business, it’s
              SpotOn.”
            </p>

            <div className="mt-4 xl:mt-12 flex self-start">
              <div className="w-[80px]">
                <Image imageName="gotham-logo-1.png" />
              </div>
              <div className="ml-4">
                <div className="text-primary text-p md:text-[24px]">
                  Bret Csencsitz
                </div>
                <div className="text-black-200 text-[12px] md:text-[14px]">
                  Managing Partner, Gotham Restaurant
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <InlineQuote sectionData={offerLPTestimonials} /> */}
      {/* <WhiteGlove
        sectionData={offerLPWhiteGlove}
        whiteGloveBg={whiteGloveImport}
        noTime
      /> */}
      <LargeFeatures
        sectionData={offerLPLargeFeatures}
        style={{ marginTop: "60px" }}
      />
      <VetsSpecial sectionData={customOffer} />
      {/* <section style={{ marginTop: "60px" }}> */}
      {/*  <TestmonialReviews sectionData={vetsLPTestimonials} /> */}
      {/* </section> */}
      <LargeCta sectionData={offerLPCTA} />
    </LpLayout>
  );
};

export default OrderingLP;
